@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* @apply bg-gradient-to-r from-gradient-2 to-gradient-1 */
  @apply bg-gradient-2
};

body, input, button {
  @apply font-roboto
};
